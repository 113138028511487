import React from "react"; 
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ContactForm from '../components/ContactForm' 
import Img from "gatsby-image"; 
import HowItWorks from "../components/HowItWorks";
import AgentvsSellFastSactable from '../components/AgentvsSellFastSacTable' 
import SeeTheDifferenceSidebar from '../components/SeeTheDifferenceSidebar'

const HowItWorksPage = () => {

  const process = [{desc:"Reach out and tell us about your house, your situation, and your selling goal"}, {desc:"Right away, we will begin our research on your property and recent sales in your neighborhood"}, {desc: "As quickly as possible, we will provide you with an offer and valuable information to help you determine how to sell"}, {desc:"If you choose to sell your house directly, we will work according to your timeframe and can be ready in just 7 days"}]
  
  return (
    <Layout headerTransparent={false}>
      <SEO
        title={'How it Works | Sell My House Fast In Sacramento, CA'}
        description={'Do you need to sell your house in Sacramento? Before you hire an agent, find out how beneficial and convenient our selling process can be!'}
        keywords={''}
      />






      <section className="py-16 section-stripe-bg bg-gray-50 ">
        <div className="flex flex-wrap px-6 md:px-8 container mx-auto ">
          <div className="w-full md:w-2/3 md:pr-4 prose">


            <h1>How The Process Works</h1>
            {process.map((item, i)=> (
            <div className="flex items-center py-6">
            <div class="bg-accent-600 flex flex-shrink-0 font-semibold h-10 items-center justify-center md:h-12 md:w-12 rounded-full text-white w-10">{i+1}</div>

              <div className="text-left pl-3">
                <span className="font-display   text-gray-800 text-lg md:text-xl leading-5 mb-1">
              {item.desc}

                </span>
                
              </div>
            </div>
            ))}
           
 



            <p><strong>What To Expect:</strong> When you reach out to us to sell your house in Sacramento, we will immediately begin looking at your property and situation to help you determine the best way to sell. We will make you a fair and straightforward offer, closing whenever you need us to. Our process is fast, fair, and convenient.</p>
            <h3><span>A Direct Sale Makes It Easy To Sell Your House In Sacramento!&nbsp;</span></h3>
            <p> <span>That’s because </span><b>we are house buyers</b><span>. We don’t list houses. We don’t sell houses for other people. We only buy them.&nbsp;</span></p>
            <p> <span>What You’ll Get When You Sell Your House to Sell Your Home For Cash Sacramento:&nbsp;</span></p>
            <ul>
              <li><span>A fair </span><b>written offer as quickly as possible</b></li>
              <li><span>No repairs. No fixing. No remodeling. We handle everything.&nbsp;</span></li>
              <li><span>No waiting. No fees. No commissions. No Hidden costs.&nbsp;</span></li>
              <li><span>No having to </span><b>deal with agents, bankers, or contractors</b><span>.</span></li>
              <li><span>You won’t </span><b>even need to clean or tidy up.</b></li>
            </ul>
            <p>Our process makes it easy to <a href="/sell-your-house/">sell your house</a> in Sacramento without the hassles, headaches, and costs of a traditional listing. We make it easy to sell your property, without having to spend money or wait around. Give us a call if you have questions, if you want to learn about our process, or if you are interested in a no-obligation offer of your own!</p>
            <h3>Find Out What We Can Offer You!</h3>
            <p><a href="/get-a-cash-offer-today/">Send us some basic details about your house to get started</a></p>


          </div>
          <div className="w-full md:w-1/3 md:pl-4">



            <div className="rounded-xl mb-2 shadow bg-white  mt-6 md:mt-0">
              <div className="px-8 pt-8">
                <h2 className="text-gray-800 font-bold text-2xl md:text-3xl leading-8">Get Your Fair Cash Offer: Start Below!</h2>
                <p class="text-gray-600 mb-2 leading-6 mt-1">We buy houses in any condition -- no commissions or fees and no obligation whatsoever. Start below by giving us a bit of information about your property or call 916-237-9457.</p>
              </div>


              <div className="p-8 pt-4"><ContactForm stacked={true} hideLabels={true} showPlaceholders={true} /></div>

            </div>
            <SeeTheDifferenceSidebar/>
          </div>
        </div>
      </section>


      <AgentvsSellFastSactable />
      <HowItWorks />
 

    </Layout>
  );
};


export default HowItWorksPage;

